import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['overlay', 'closeBtn'];

  connect() {
    this.overlay = this.overlayTarget;
    this.hidePastEvents();
  }

  open(e) {
    e.preventDefault();
    let overlay = this.overlay;
    let closeBtn = this.closeBtnTarget;

    // if inside the clubroom_events component
    // append top level details element to the body to avoid being contained
    if (this.element.classList.contains('swiper-slide')) {
      document.body.appendChild(overlay);
      closeBtn.addEventListener('click', this.close.bind(this));
    }

    overlay.classList.add('-show');
    document.body.classList.add('-is-open-modal');
  }

  close(e) {
    e.preventDefault();
    let overlay = this.overlay;
    // if inside the clubroom_events component
    // remove top level details element from the body
    if (this.element.classList.contains('swiper-slide')) {
      this.element.appendChild(overlay);
    }
    overlay.classList.remove('-show');
    document.body.classList.remove('-is-open-modal');
  }

  hidePastEvents() {
    let MINUTES_IN_MILLISECONDS = 30 * 60 * 1000;
    let now = new Date();
    let eventDateTime = new Date(Date.parse(this.element.dataset.datetime));
    eventDateTime = new Date(eventDateTime.getTime() + MINUTES_IN_MILLISECONDS);
    console.log({ now, eventDateTime });
    if (eventDateTime < now) this.element.remove();
    this.dispatchCompletedEvent();
  }

  dispatchCompletedEvent() {
    const event = new CustomEvent('eventCardProcessed', { bubbles: true });
    this.element.dispatchEvent(event);
  }
}
